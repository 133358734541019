
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');


body {
    font-family: 'Open Sans',
    sans-serif;
    color:#171717; 
    }


    h1,
    h2,
    h3 {
        font-family: 'Share Tech Mono', monospace;
    }

    .hero-title {
        font-size: 4em;
    }
    .hero-subtitle {
        font-size: 2em;
    }
    /*
    footer {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 0;
        background:#171717 !important;
        color: #fcfcfc;
    }

    .footer {
        padding: 2em;
    }
*/
    svg {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 0;
        opacity: .8;
    } 
    
    .jumbo {
        margin-top: 10vh;
        padding: 4em;

        .title{
            font-size: 58px;
        }
    }

    .title {
       //margin-top: 2em;
       font-style: normal;
       font-weight: normal;
       font-size: 56px;
       line-height: 66px;

    }

    /*.copyright {
        font-size: 8pt;
        position: absolute;
        bottom: 2em;
        right: 2em;
    }*/

    #cta {
        margin: 0 auto;
    }


.brand {
    background-color: #005B69;
    color:#fcfcfc;
}

.brand:hover {
    background-color: #0090A6;
    color:#ffffff;
}  

.chaos{
    z-index:-20;
    position:fixed;
    opacity: .666;
    top: 60%;
    left: 50%;
    margin-top: -500px; /* Negative half of height. */
    margin-left: -250px; /* Negative half of width. */    
}

.logo{
    position:fixed;
    opacity: .666;
    z-index: -40;   
    /* width:10em; */
    /* margin: 0 auto; */
    /* width:20%; */
    /* padding:1em; */
    /* margin-left:45em; */
    /* margin-top:15em; */
    width: 500px;
    height: 500px;
    top: 50%;
    left: 50%;
    /* margin-top: -250px; Negative half of height. */
    margin-top: -400px; /* Negative half of height. */
    margin-left: -250px; /* Negative half of width. */
}


$lateralBlue: #0090A6;
$primary: $lateralBlue !default;


//Import from bulma
@import "./node_modules/bulma/sass/utilities/_all.sass";
@import "./node_modules/bulma/sass/base/_all.sass";
@import "./node_modules/bulma/sass/components/_all.sass";
@import "./node_modules/bulma/sass/elements/_all.sass";
@import "./node_modules/bulma/sass/form/_all.sass";
@import "./node_modules/bulma/sass/grid/_all.sass";
@import "./node_modules/bulma/sass/helpers/_all.sass";
@import "./node_modules/bulma/sass/layout/_all.sass";
